<template>
  <div>
    <h5 class="font-handwriting">{{ "3. Success" | __t("members__step-success") }}</h5>

    <div v-if="paymentLevel === PAYMENT_LEVEL.FIRST_TIME || paymentLevel === PAYMENT_LEVEL.UPGRADE" data-cy="success-description">
      <spinner-small v-if="isWaitingForConfirmation" text="Waiting for confirmation" />

      <div v-else>
        <p>
          {{ "Thank you for your payment." | __t("`forms__upgrade__thankyou") }}
        </p>

        <p>
          {{ "You've upgraded to the" | __t(`forms__upgrade__success-message`) }}
          {{ newPlanId | __t(`forms__upgrade__success-${newPlanId}`) }} {{ "plan" | __t(`forms__upgrade__success-plan`) }}.
        </p>

        <div class="text-right text-white mt-4">
          <b-button
            variant="success"
            @click="
              $emit('success-redirect');
              $emit('close', true);
            "
            pill
            data-cy="success-continue"
          >
            <span class="text-uppercase">{{ "Continue" | __t("button__continue") }}</span>
          </b-button>
        </div>
      </div>
    </div>

    <div v-else>
      <p data-cy="success-description">
        {{ "Your account will be downgraded from the" | __t(`forms__downgrade__success-message`) }}
        {{ previousPlanId | __t(`forms__downgrade__success-${newPlanId}`) }}
        {{ "plan to the" | __t("forms__downgrade__plan-to-the") }}
        {{ newPlanId | __t(`forms__downgrade__success-${newPlanId}`) }}
        {{ "at the end of your current membership" | __t("forms__downgrade__end") }}.
      </p>

      <div class="text-right text-white mt-4">
        <b-button
          variant="success"
          @click="
            $emit('success-redirect');
            $emit('close', true);
          "
          pill
          data-cy="success-continue"
        >
          <span class="text-uppercase">{{ "Continue" | __t("button__continue") }}</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { BButton } from "bootstrap-vue";

import { PAYMENT_LEVEL } from "@/constants/billing";

import { toSlug } from "@/helpers/utils";
import { convertTimestampToDate } from "@/helpers/date";

import SpinnerSmall from "@/components/Spinner/Small";

export default {
  name: "UserUpgrade",
  components: { BButton, SpinnerSmall },
  props: {
    previousPlanId: {
      type: String,
      default: null,
    },
    newPlanId: {
      type: String,
      default: null,
    },
    currentEndingDate: {
      type: String,
      default: null,
    },
    paymentLevel: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters("auth", ["billing"]),
    currentPlanId() {
      return this.billing.plan;
    },

    isWaitingForConfirmation() {
      const { currentPlanId, newPlanId } = this;
      return currentPlanId !== newPlanId;
    },
  },
  data: () => ({
    previousPlanId: null,
    newPlanId: null,
    paymentLevel: null,
    currentEndingDate: null,

    PAYMENT_LEVEL,
  }),
  created() {
    const { previousPlanId, newPlanId, currentEndingDate } = this;

    this.previousPlanId = previousPlanId;
    this.newPlanId = newPlanId;
    // this.paymentLevel = paymentLevel;
    this.currentEndingDate = currentEndingDate;
  },
  methods: {
    toSlug,
    convertTimestampToDate,
  },
};
</script>

<style lang="scss" scoped>
.features {
  list-style: none;
  margin: 10px 0;
  padding: 0;
}
</style>
