<template>
  <div>
    <white-card :is-centered="false">
      <h4 class="text-uppercase font-handwriting">
        {{ plan.name | __t("pricing__plan-" + toSlug(plan.name)) }}
      </h4>

      <p>{{ plan.description | __t("pricing__plan-descr-" + toSlug(plan.id)) }}</p>

      <ul class="features reset-list">
        <li v-for="feature in plan.features" :key="feature">
          📖 <span v-html="$options.filters.__t(feature, 'pricing__feature-' + toSlug(feature))"></span>
        </li>
      </ul>

      <div class="border-top divider mt-4 mb-4" />

      <b-row>
        <b-col>
          <strong>{{ "Total" | __t("pricing__total") }}</strong>
        </b-col>

        <b-col class="text-right">
          {{ CURRENCIES_SYMBOLS[currency] }}{{ amount }}
          <span v-if="frequency === PLANS_FREQUENCY.PER_MONTH">{{ "/ month" | __t("pricing__frequency-permonth") }}</span>
          <span v-else-if="frequency === PLANS_FREQUENCY.PER_YEAR">{{ "/ year" | __t("pricing__frequency-yearly") }}</span>
        </b-col>
      </b-row>
    </white-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { BRow, BCol } from "bootstrap-vue";

import { PLANS_FREQUENCY, PLANS_FREQUENCY_LIST } from "@/constants/users";
import { CURRENCIES_SYMBOLS, CURRENCIES_LIST, CURRENCIES_DEFAULT } from "@/constants/billing";

import { toSlug } from "@/helpers/utils";

import WhiteCard from "@/components/Base/WhiteCard";

export default {
  name: "BillingSummary",
  components: { BRow, BCol, WhiteCard },
  props: {
    planId: {
      type: String,
      required: true,
    },

    amount: {
      type: Number,
      required: true,
    },
    currency: {
      validator: (value) => {
        return CURRENCIES_LIST.includes(value);
      },
      type: String,
      default: CURRENCIES_DEFAULT,
    },
    frequency: {
      validator: (value) => {
        return PLANS_FREQUENCY_LIST.includes(value);
      },
      required: true,
    },
  },
  computed: {
    ...mapGetters("auth", ["fetchPlansOptions"]),
    plan() {
      const { planId } = this;
      const plans = this.fetchPlansOptions;
      return plans.find(({ id }) => id === planId);
    },
  },
  data: () => ({
    PLANS_FREQUENCY,
    CURRENCIES_SYMBOLS,
  }),
  methods: {
    toSlug,
  },
};
</script>
