<template>
  <b-modal
    v-model="localIsShowingModal"
    @hidden="localIsShowingModal = false"
    size="lg"
    centered
    hide-footer
    content-class="bg-dark"
    body-class="d-flex flex-column"
    :no-close-on-backdrop="isWaitingForConfirmation"
    :no-close-on-esc="isWaitingForConfirmation"
  >
    <template #modal-header>
      <div class="w-100">
        <h5 class="font-handwriting">
          {{ "3. Success" | __t("billing__headline__3-success") }}
        </h5>
      </div>
    </template>

    <div>
      <div v-if="paymentLevel === PAYMENT_LEVEL.FIRST_TIME || paymentLevel === PAYMENT_LEVEL.UPGRADE">
        <spinner-small
          v-if="isWaitingForConfirmation"
          :text="`Waiting for confirmation` | __t('status____waiting-for-confirmation')"
        />

        <div v-else>
          <p>
            {{ "Thank you for your payment." | __t("`billing__thankyou-for-your-payment") }}
          </p>

          <p>
            {{ "You've upgraded to the" | __t(`billing__upgrade__1-you-ve-upgraded-to-the`) }}
            {{ newPlanId | __t(`forms__upgrade__success-${newPlanId}`) }} {{ "plan" | __t(`billing__upgrade__2-plan`) }}
          </p>

          <div class="text-right text-white mt-4">
            <b-button
              variant="success"
              @click="
                $emit('success-redirect');
                localIsShowingModal = false;
              "
              pill
            >
              <span class="text-uppercase">{{ "Continue" | __t("button__continue") }}</span>
            </b-button>
          </div>
        </div>
      </div>

      <div v-else>
        <p>
          {{ "Your account will be downgraded from the" | __t(`billing__downgrade__1-you-will-be-downgraded-from-the`) }}
          {{ previousPlanId }}
          {{ "plan to the" | __t("billing__downgrade__2-plan-to-the") }}
          {{ newPlanId }}
          {{ "at the end of your current membership" | __t("billing__downgrade__3-at-the-end-of-your-membership") }}.
        </p>

        <div class="text-right text-white mt-4">
          <b-button
            variant="success"
            @click="
              $emit('success-redirect');
              localIsShowingModal = false;
            "
            pill
          >
            <span class="text-uppercase">{{ "Continue" | __t("button__continue") }}</span>
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import { BModal, BButton } from "bootstrap-vue";

import { PAYMENT_LEVEL } from "@/constants/billing";

import { sleep } from "@/helpers/utils";

import SpinnerSmall from "@/components/Spinner/Small";

export default {
  name: "SuccessPlan",
  components: { BModal, BButton, SpinnerSmall },
  props: {
    newPlanId: {
      type: String,
      default: "",
    },
    previousPlanId: {
      type: String,
      default: "",
    },
    paymentLevel: {
      type: String,
      default: "",
    },

    isShowingModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({ plan: (state) => state?.auth?.user?.billing?.plan }),
    isWaitingForConfirmation() {
      const { plan, newPlanId } = this;
      return plan !== newPlanId;
    },

    localIsShowingModal: {
      get() {
        return this.isShowingModal;
      },
      set(value) {
        this.$emit("update:is-showing-modal", value);
      },
    },
  },
  data: () => ({
    PAYMENT_LEVEL,
  }),
  watch: {
    isShowingModal(newValue) {
      if (newValue) this.startConfetti();
    },
  },
  methods: {
    async startConfetti() {
      this.$confetti.start();

      await sleep(3000);
      this.$confetti.stop();
    },
  },
};
</script>
