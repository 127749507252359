<template>
  <div>
    <div id="checkout" />

    <spinner-small v-if="isLoading" text="Checking connection" />

    <div class="text-right mt-4">
      <b-button variant="empty" @click="$emit('close')" data-cy="cancel-button">
        <span class="text-info">{{ "Cancel" | __t("button__cancel") }}</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { INTENT } from "@/store/actions";

import { BButton } from "bootstrap-vue";

import SpinnerSmall from "@/components/Spinner/Small";

export default {
  name: "CreateCreditCard",
  components: { BButton, SpinnerSmall },
  computed: {
    ...mapState({ stripe: (state) => state.checkout.stripe }),
  },
  data: () => ({
    isLoading: true,
  }),
  async mounted() {
    const { clientSecret } = await this.intentCreatePaymentMethod();

    this.checkout = await this.stripe.initEmbeddedCheckout({ clientSecret });
    this.checkout.mount("#checkout");

    this.isLoading = false;
  },
  methods: {
    ...mapActions("userCreditCards", { intentCreatePaymentMethod: INTENT }),
  },
};
</script>
