<template>
  <div class="billing-section">
    <div v-if="hasCountdown">
      <h2 class="font-handwriting fw-700 fs-48 mb-4">Secure Your Lifetime Access,<br />This Special Offer Ends In:</h2>

      <div class="countdown text-center mb-4">
        <countdown :is-days="false" :end="end" class="d-inline-flex m-auto" />
      </div>
    </div>

    <div v-if="hasRiskFreeBanner" class="fisk-free mb-5 mt-5">
      <div class="d-flex flex-column flex-lg-row align-items-center justify-content-center m-auto" style="max-width: 800px">
        <img
          class="d-block mb-4 mb-lg-0 flex-shrink-0 mr-lg-5"
          src="https://images.clickfunnels.com/cdn-cgi/image/f=auto,q=80/https://statics.myclickfunnels.com/image/841225/file/6ca7bd2db1888d4633b6ca39cc66a066.png"
          style="width: 200px; height: 200px"
        />
        <div class="text-left font-poppins">
          <p class="fw-700"><b>30-day money-back guarantee</b></p>
          <p class="">
            Remember you don’t have to say YES right now. You only have to say MAYBE. Go through for 30 days risk-free from the
            day of purchase.
          </p>
          <p class="mb-0 ">
            Just reach out through our email and get a full, friendly and fast refund. No questions asked.
          </p>
        </div>
      </div>
    </div>

    <div class="plans mt-5">
      <user-upgrade-selection
        @select-plan="handleSelectPlan"
        :user-plan-id="projectPlanId"
        :price="optionPrice"
        :frequency.sync="optionFrequency"
      />
    </div>

    <b-modal
      v-model="isShowingUpgrade"
      @hidden="isShowingUpgrade = false"
      body-bg-variant="dark"
      centered
      hide-header
      hide-footer
      size="lg"
      data-cy="checkout-modal"
    >
      <user-upgrade
        :option-plan-id="planId"
        :option-price="optionPrice"
        :option-frequency="optionFrequency"
        option-payment-level="first-time"
        :is-showing-breadcrumb="false"
        @close="isShowingUpgrade = false"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { BModal } from "bootstrap-vue";

import { toSlug } from "@/helpers/utils";

import Countdown from "@/components/Base/Countdown";
import UserUpgrade from "@/components/Base/PricingUpgrade/";
import UserUpgradeSelection from "@/components/Base/PricingUpgrade/Selection";

export default {
  name: "BillingSection",
  components: { BModal, Countdown, UserUpgrade, UserUpgradeSelection },
  props: {
    end: {
      type: String,
      default: null,
    },

    hasRiskFreeBanner: {
      type: Boolean,
      default: false,
    },
    hasCountdown: {
      type: Boolean,
      default: false,
    },

    optionPrice: {
      type: String,
      default: "special",
    },
    optionFrequency: {
      type: String,
      default: "single-time",
    },
  },
  computed: {
    ...mapState({
      userCreated: (state) => state?.auth?.user?.public?.createdAt,
      mode: (state) => state.customize["dark-mode"],
      projectsBilling: (state) => state.projectsBilling.items,
    }),
    projectPlanId() {
      return this.projectsBilling.plan;
    },
  },
  data: () => ({
    planId: null,

    isShowingUpgrade: false,
  }),
  methods: {
    handleSelectPlan(planId) {
      this.planId = planId;
      this.isShowingUpgrade = true;
    },

    redirectToDashboard() {
      this.$router.push({ name: "Projects" });
    },

    i18(value) {
      return this.$options.filters.__t(value, "specialoffer__" + toSlug(value));
    },
  },
};
</script>

<style lang="scss">
@use "sass:map";
@import "@/assets/scss/_settings";

.billing-section {
  .countdown {
    .vuejs-countdown li {
      margin: 0 28px;
      &::after {
        right: -30px;
        top: 20px;
        font-weight: 900;
      }
    }
    .vuejs-countdown .digit {
      font-size: 46px;
      font-family: "Libre Baskerville";
      @media (max-width: map.get($grid-breakpoints, "lg")) {
        font-size: 30px;
        font-weight: 500;
      }
    }
    .vuejs-countdown .text {
      font-size: 16px;
      font-family: "Libre Baskerville";
      text-transform: capitalize;
    }
  }

  .plans {
    h5.title {
      display: none;
    }
  }
}
</style>
