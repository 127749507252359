<template>
  <div>
    <b-button @click="isShowingCreateCard = true" variant="outline-primary" class="float-right d-inline-block" pill>
      <!-- <img src="@/assets/icons/plus.svg" class="icon icon-xs" /> -->

      {{ "Add Credit Card" | __t("billing__button__add-credit-card") }}
    </b-button>

    <h3 class="text-uppercase">
      {{ "Credit Cards" | __t("billing__headline___credit-cards") }}
    </h3>

    <div class="pt-3 pl-3 pr-3 pb-2">
      <credit-card
        v-for="i in itemsSorted"
        :key="i.id"
        :id="i.id"
        :brand="i.brand"
        :last4="i.last4"
        :expMonth="i.expMonth"
        :expYear="i.expYear"
      />

      <p v-if="!itemsSorted.length">
        <em>{{ "No payment method has been added yet." | __t("billing__no-payment-method-has-been-added-yet") }}</em>
      </p>
    </div>

    <b-modal
      v-model="isShowingCreateCard"
      @hidden="isShowingCreateCard = false"
      size="xl"
      centered
      hide-footer
      content-class="bg-dark"
      body-class="d-flex flex-column"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
    >
      <template #modal-header>
        <div class="w-100">
          <b-button @click="isShowingCreateCard = false" variant="transparent" class="float-right">
            <img src="@/assets/icons/rounded-cross.svg" class="icon-sm" />
          </b-button>

          <h5 class="font-handwriting">
            {{ "Add Payment Method" | __t("billing__headline__add-payment-method") }}
          </h5>

          <p class="mb-0">
            {{ "Add a new payment method for a faster checkout." | __t("billing__add-a-new-payment-method-for-checkout") }}
          </p>
        </div>
      </template>

      <div>
        <create-credit-card @close="isShowingCreateCard = false" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { FETCH, LISTEN } from "@/store/actions";

import { BModal, BButton } from "bootstrap-vue";

import CreditCard from "./Card.vue";
import CreateCreditCard from "./Create.vue";

export default {
  name: "CreditCards",
  components: { BModal, BButton, CreditCard, CreateCreditCard },
  computed: {
    ...mapGetters("userCreditCards", ["itemsSorted"]),
  },
  data: () => ({
    isShowingCreateCard: false,

    isLoading: true,
  }),
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions("userCreditCards", { fetchCreditCards: FETCH, listenCreditCards: LISTEN }),
    async init() {
      this.isLoading = true;

      await this.fetchCreditCards();
      await this.listenCreditCards();

      this.isLoading = false;
    },
  },
};
</script>
