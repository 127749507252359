<template>
  <div>
    <h5 class="font-handwriting">{{ "2. Payment" | __t("members__step-payment") }}</h5>

    <b-row>
      <b-col>
        <checkout-stripe-subscription
          v-on="$listeners"
          :new-plan-id="planId"
          :previous-plan-id="userPlanId"
          :amount="amount"
          :frequency="frequency"
          :price="price"
          :payment-method="paymentMethod"
          :is-loading="isLoading"
        />
      </b-col>

      <b-col>
        <checkout-summary-subscription
          v-if="!isLoading"
          :plan-id="planId"
          :amount-today="amountProrated"
          :amount-frequency="amountFrequency"
          :frequency="frequency"
          :is-loading="isLoading"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { STRIPE_GET_SUBSCRIPTION, STRIPE_GET_PRORATED } from "@/store/actions";

import { BRow, BCol } from "bootstrap-vue";

import { PLANS_FREQUENCY_LIST, PLANS_PRICE_LIST } from "@/constants/users";
import { PAYMENT_LEVEL } from "@/constants/billing";

import CheckoutStripeSubscription from "./StripeSubscription";
import CheckoutSummarySubscription from "./SummarySubscription";

export default {
  name: "UserUpgrade",
  components: {
    BRow,
    BCol,
    CheckoutStripeSubscription,
    CheckoutSummarySubscription,
  },
  props: {
    planId: {
      type: String,
      required: true,
    },

    plan: {
      type: Object,
      required: true,
    },

    paymentLevel: {
      type: String,
      default: null,
    },
    frequency: {
      validator: (value) => {
        return PLANS_FREQUENCY_LIST.includes(value);
      },
      required: true,
    },
    price: {
      validator: (value) => {
        return PLANS_PRICE_LIST.includes(value);
      },
      type: String,
      required: true,
    },

    userPlanId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapState({
      userPlanAmount: (state) => state?.auth?.user?.billing?.amount,
      userPlanFrequency: (state) => state?.auth?.user?.billing?.frequency,
      userPlanSubscriptionId: (state) => state?.auth?.user?.billing?.subscriptionId,
      userPlanCustomerId: (state) => state?.auth?.user?.billing?.customerId,
    }),
    ...mapGetters("auth", ["isRecentlyJoined"]),
    amount() {
      const { userPlanAmount, plan, frequency, price, paymentLevel } = this;

      if (paymentLevel === PAYMENT_LEVEL.UPGRADE) return plan[frequency][price] - userPlanAmount;
      if (paymentLevel === PAYMENT_LEVEL.DOWNGRADE) return null;
      return plan[frequency][price];
    },
    amountFrequency() {
      const { plan, frequency, price } = this;
      return plan[frequency][price];
    },
  },
  data: () => ({
    amountProrated: 0,

    paymentMethod: {},
    subscription: {},

    isSuccess: false,
    isLoading: true,
  }),
  async mounted() {
    const { userPlanCustomerId, userPlanSubscriptionId, planId } = this;
    const { frequency, price } = this;
    const result = await this.getSubscriptionStripe({ subscriptionId: userPlanSubscriptionId });

    const { proratedAmount: amountProrated } = await this.getProratedAmountStripe({
      customerId: userPlanCustomerId,
      subscriptionId: userPlanSubscriptionId,
      newPlanId: planId,
      frequency,
      price,
    });

    this.paymentMethod = result?.paymentMethod || {};
    this.subscription = result?.subscription || {};
    this.amountProrated = amountProrated;
    this.isLoading = false;
  },
  methods: {
    ...mapActions("checkout", { getSubscriptionStripe: STRIPE_GET_SUBSCRIPTION, getProratedAmountStripe: STRIPE_GET_PRORATED }),
  },
};
</script>
