<template>
  <div>
    <b-row class="mt-4" no-gutters>
      <b-col>
        <div class="ml-2 mr-2 p-2 bg-dark-jungle rounded">
          <p class="mb-0 ">
            <small>{{ "Plan" | __t("billing__headline__plan") }}</small>
          </p>

          <strong class="text-capitalize">{{ currentPlan }}</strong>

          <div v-if="currentPlan !== 'free'">
            <small>
              <em>
                {{
                  "To upgrade or cancel your subscription, click on 'Manage Plan'."
                    | __t("billing__to-upgrade-or-cancel-click-on-manage-plan")
                }}
              </em>
            </small>
          </div>

          <p class="mt-2 mb-0">
            <small>{{ "Credits" | __t("billing__headline__credits") }}</small>
          </p>
          <strong>{{ Math.floor(billing.monthly?.[currentMonth]?.credits || 0) }}</strong>
          <small> / {{ LIMITS_BY_PLANS[currentPlan][LIMITS.CREDITS] }}</small>

          <p>
            <em>{{ "The credits are reset on the 1st of the month." | __t("billing__credits-are-reset-on-the-1st") }}</em>
          </p>

          <p class="mb-0 ">
            <small>{{ "Storage" | __t("billing__headline__storage") }}</small>
          </p>
          <strong>
            {{ getBytesToMegabytes(billing.active?.storage || 0) }}
            <small class="smaller">MB</small>
            <small>
              / {{ getBytesToMegabytes(LIMITS_BY_PLANS[currentPlan][LIMITS.STORAGE]) }}<small class="smaller">MB</small>
            </small>
          </strong>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="currentPlan !== PLANS.FREE" no-gutters>
      <b-col>
        <div class="mt-4 ml-2 mr-2 p-2 bg-dark-jungle rounded">
          <h3 class="text-uppercase">{{ "Manage Plan" | __t("billing__headline__manage-plan") }}</h3>

          <billing-upgrade />
        </div>

        <div class="mt-4 ml-2 mr-2 p-2 bg-dark-jungle rounded">
          <billing-credit-cards />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import { BRow, BCol } from "bootstrap-vue";

import { PLANS } from "@/constants/users";
import { LIMITS, LIMITS_BY_PLANS } from "@/constants/billing";

import { getBytesToMegabytes } from "@/helpers/utils";
import { getCurrentMonth } from "@/helpers/date";

import BillingCreditCards from "@/components/Auth/Billing/CreditCards/";
import BillingUpgrade from "@/components/Auth/Billing/Upgrade";

export default {
  name: "Billing",
  components: {
    BRow,
    BCol,
    BillingCreditCards,
    BillingUpgrade,
  },
  computed: {
    ...mapState({
      billing: (state) => state.memberBilling.items,
    }),
    ...mapGetters("auth", ["planLevel"]),
    currentMonth() {
      return getCurrentMonth();
    },
    currentPlan() {
      const { billing } = this;
      return billing.plan || "free";
    },
    limitsFiltered() {
      return [];
    },
  },
  data: () => ({
    LIMITS,
    LIMITS_BY_PLANS,
    PLANS,
  }),
  methods: {
    getBytesToMegabytes,
  },
};
</script>
