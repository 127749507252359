<template>
  <div>
    <h4 class="text-uppercase font-handwriting">
      {{ plan.name | __t("pricing__plan-" + toSlug(plan.name)) }}
    </h4>

    <p>{{ plan.description | __t("pricing__plan-descr-" + toSlug(plan.id)) }}</p>

    <ul class="features reset-list">
      <li v-for="feature in plan.features" :key="feature.text">
        <img :src="require(`@/assets/icons/${feature.icon}.svg`)" width="20" height="20" class="mr-3" />
        <span v-html="$options.filters.__t(feature.text, 'pricing__feature-' + toSlug(feature.text))"></span>
      </li>
    </ul>

    <div class="border-top divider mt-4 mb-4" />

    <b-row>
      <b-col>
        <strong>{{ "Total" | __t("pricing__total") }}</strong>
      </b-col>

      <b-col class="text-right">
        {{ CURRENCIES_SYMBOLS[currency] }}{{ amountToday }} today then {{ CURRENCIES_SYMBOLS[currency] }}{{ amountFrequency }}
        <span class="text-lowercase">{{ PLANS_FREQUENCY_TEXT[frequency] }}</span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { BRow, BCol } from "bootstrap-vue";

import { PLANS_FREQUENCY, PLANS_FREQUENCY_LIST, PLANS_FREQUENCY_TEXT } from "@/constants/users";
import { CURRENCIES_SYMBOLS, CURRENCIES_LIST, CURRENCIES_DEFAULT } from "@/constants/billing";

import { toSlug } from "@/helpers/utils";

export default {
  name: "SubscriptionSummary",
  components: { BRow, BCol },
  props: {
    planId: {
      type: String,
      required: true,
    },

    amountToday: {
      type: Number,
      required: true,
    },
    amountFrequency: {
      type: Number,
      required: true,
    },
    currency: {
      validator: (value) => {
        return CURRENCIES_LIST.includes(value);
      },
      type: String,
      default: CURRENCIES_DEFAULT,
    },
    frequency: {
      validator: (value) => {
        return PLANS_FREQUENCY_LIST.includes(value);
      },
      required: true,
    },
  },
  computed: {
    ...mapGetters("auth", ["fetchPlansOptions"]),
    plan() {
      const { planId } = this;
      const plans = this.fetchPlansOptions;
      return plans.find(({ id }) => id === planId);
    },
  },
  data: () => ({
    PLANS_FREQUENCY,
    PLANS_FREQUENCY_TEXT,
    CURRENCIES_SYMBOLS,
  }),
  methods: {
    toSlug,
  },
};
</script>
