<template>
  <b-modal
    v-model="localIsShowingModal"
    @hidden="localIsShowingModal = false"
    size="lg"
    centered
    hide-footer
    content-class="bg-dark"
    body-class="d-flex flex-column"
    :no-close-on-backdrop="isWaitingForConfirmation"
    :no-close-on-esc="isWaitingForConfirmation"
  >
    <template #modal-header>
      <div class="w-100">
        <h5 class="font-handwriting">
          {{ "3. Success" | __t("billing__headline__3-success") }}
        </h5>
      </div>
    </template>

    <div>
      <spinner-small
        v-if="isWaitingForConfirmation"
        :text="`Waiting for confirmation` | __t('status____waiting-for-confirmation')"
      />

      <div v-else>
        <p>
          {{ "Thank you." | __t("`billing__thankyou") }}
        </p>

        <p>
          {{ "Your credit card has been added." | __t(`billing__your-credit-card-has-been-added`) }}
        </p>

        <div class="text-right text-white mt-4">
          <b-button
            variant="success"
            @click="
              $emit('success-redirect');
              localIsShowingModal = false;
            "
            pill
          >
            <span class="text-uppercase">{{ "Continue" | __t("button__continue") }}</span>
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import { CONFIRM } from "@/store/actions";

import { BModal, BButton } from "bootstrap-vue";

import { PAYMENT_LEVEL } from "@/constants/billing";

import { sleep } from "@/helpers/utils";

import SpinnerSmall from "@/components/Spinner/Small";

export default {
  name: "SuccessPlan",
  components: { BModal, BButton, SpinnerSmall },
  props: {
    sessionId: {
      type: String,
      default: "",
    },

    isShowingModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    localIsShowingModal: {
      get() {
        return this.isShowingModal;
      },
      set(value) {
        this.$emit("update:is-showing-modal", value);
      },
    },
  },
  data: () => ({
    isSuccess: false,
    isWaitingForConfirmation: true,

    PAYMENT_LEVEL,
  }),
  watch: {
    isShowingModal(newValue) {
      if (newValue) this.triggerConfirmCreatePaymentMethod();
    },
    isSuccess(newValue) {
      if (newValue) this.startConfetti();
    },
  },
  methods: {
    ...mapActions("userCreditCards", { confirmCreatePaymentMethod: CONFIRM }),
    async triggerConfirmCreatePaymentMethod() {
      const { sessionId } = this;

      const result = await this.confirmCreatePaymentMethod({ sessionId });

      const { isSuccess } = result;

      if (isSuccess) {
        this.isWaitingForConfirmation = false;
        this.isSuccess = true;
      }
    },

    async startConfetti() {
      this.$confetti.start();

      await sleep(3000);
      this.$confetti.stop();
    },
  },
};
</script>
