<template>
  <div class="p-3 rounded-lg selection-card" :class="plan.class">
    <div
      v-if="isPopular && (!isAuthenticated || userPlanId === PLANS.FREE)"
      class="pt-2 pb-2 pl-4 pr-4 float-right bg-dark-jungle rounded-lg text-white"
    >
      <img src="@/assets/icons/sparkling.svg" class="mr-1" width="20" height="20" />
      <strong>{{ "Most Popular" | __t("pricing__most-popular-plan") }}</strong>
    </div>
    <!--      <span v-else-if="id === userPlanId">
        {{ "Current Plan" | __t("pricing__current-plan") }}
      </span> -->

    <h3 class="text-uppercase">
      {{ plan.name | __t("pricing__plan-" + toSlug(plan.name)) }}
    </h3>

    <div v-if="!isCurrent" class="mt-4">
      <p
        v-if="!isAuthenticated || userPlanId === PLANS.FREE || paymentLevel === PAYMENT_LEVEL.UPGRADE"
        class="mb-0 text-white"
        style="line-height: 1.3em;"
      >
        <small class="font-handwriting">$US</small>
        <span class="font-handwriting text-xxl bold">{{ amount }}</span>
        <small v-html="$options.filters.__t(frequencyText, 'pricing__frequency-' + toSlug(frequencyText))" />
      </p>

      <p
        v-if="comparedToText && (paymentLevel === PAYMENT_LEVEL.FIRST_TIME || paymentLevel === PAYMENT_LEVEL.UPGRADE)"
        class="text-white"
      >
        <small v-html="$options.filters.__t(comparedToText, 'pricing__old-amount-' + toSlug(comparedToText))" />
      </p>

      <p
        v-if="comparedToTextAfter && userPlanId === PLANS.FREE"
        style="font-size: 1em; margin-top: 20px; font-weight: 500"
        class="font-poppins mb-0"
        v-html="$options.filters.__t(comparedToTextAfter, 'pricing__old-amount-after-' + toSlug(comparedToTextAfter))"
      />
    </div>

    <spacer height="50px" />

    <div>
      <p v-if="isCurrent">
        <img src="@/assets/icons/check.svg" class="icon mr-2" :class="{ 'icon-white': mode === 'dark' || isForceDark }" />
        <strong>
          {{ "Your Current Plan" | __t("pricing__your-current-plan") }}
        </strong>

        <strong>
          <span v-if="userPlanRenewAt">
            {{ "Subscription will be renewed on" | __t("pricing__plan-subscription-renew") }}
            {{ convertTimestampToDate(userPlanRenewAt) }}.
          </span>

          <span v-else-if="userPlanEndAt">
            {{ "Subscription was cancelled and will end on" | __t("pricing__plan-subscription-cancel") }}
            {{ convertTimestampToDate(userPlanEndAt) }}.
            <br />
            {{ "It will be replaced by" | __t("pricing__plan-subscription-cancel-2") }}
            {{ userNewPlanId || "free" }}.
          </span>
        </strong>
      </p>

      <b-button v-else-if="!userPlanEndAt" @click="$emit('click')" variant="transparent" class="w-100 bg-white rounded text-l">
        {{ buttonText | __t("pricing__" + toSlug(String(buttonText))) }}
      </b-button>
    </div>

    <spacer height="50px" />

    <p class="text-white">
      {{ plan.description | __t("pricing__target-" + toSlug(plan.description)) }}
    </p>

    <ul v-if="plan.features" class="features text-left mt-3">
      <li v-for="(feature, key) in getFeatures" :key="key" class="d-flex align-items-start mb-3">
        <img :src="require(`@/assets/icons/${feature.icon}.svg`)" class="icon mr-1" style="width: 26px; height: 26px" />

        <span
          class="ml-2 mt-1 text-white"
          v-html="$options.filters.__t(feature.text, 'pricing__pro-' + toSlug(String(feature.text)))"
        ></span>
      </li>
    </ul>

    <spacer height="50px" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { BButton } from "bootstrap-vue";

import { PLANS, PLANS_FREQUENCY_TEXT } from "@/constants/users";
import { PAYMENT_LEVEL } from "@/constants/billing";

import { toSlug } from "@/helpers/utils";
import { convertTimestampToDate } from "@/helpers/date";

import Spacer from "@/components/Layouts/Spacer";

export default {
  name: "PlanCardPrice",
  components: { BButton, Spacer },
  props: {
    id: {
      type: String,
      required: true,
    },
    plan: {
      type: Object,
      required: true,
    },

    frequency: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },

    isForceDark: {
      type: Boolean,
      default: false,
    },
    isPopular: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      mode: (state) => state.customize["dark-mode"],
      userPlanId: (state) => state?.auth?.user?.billing?.plan,
      userNewPlanId: (state) => state?.auth?.user?.billing?.newPlanId,
      userPlanAmount: (state) => state?.auth?.user?.billing?.amount,
      userPlanFrequency: (state) => state?.auth?.user?.billing?.frequency,
      userPlanStatus: (state) => state?.auth?.user?.billing?.status,
      userPlanRenewAt: (state) => state?.auth?.user?.billing?.renewAt,
      userPlanEndAt: (state) => state?.auth?.user?.billing?.endAt,
    }),
    ...mapGetters("auth", ["planLevel", "isAuthenticated"]),
    amount() {
      const { id, plan, frequency, price, paymentLevel, isAuthenticated } = this;

      if (!isAuthenticated) return plan[frequency][price];
      else if (paymentLevel === PAYMENT_LEVEL.UPGRADE) return plan[frequency][price];
      else if (paymentLevel === PAYMENT_LEVEL.DOWNGRADE && id !== PLANS.FREE) return plan[frequency][price];
      else if (paymentLevel === PAYMENT_LEVEL.DOWNGRADE) return null;
      return plan[frequency][price];
    },

    getFeatures() {
      return this.plan.features;
    },

    comparedToText() {
      const { plan, frequency, price, paymentLevel } = this;
      if (paymentLevel === PAYMENT_LEVEL.UPGRADE) return plan[frequency][`${paymentLevel}-text`];
      return plan[frequency][`${price}-text`];
    },
    comparedToTextAfter() {
      const { plan, frequency, price } = this;
      return plan?.[frequency]?.[`${price}-text-after`];
    },
    buttonText() {
      const { planLevel: userPlanLevel, isFree } = this;
      const { level } = this.plan;

      if (isFree && level < userPlanLevel && level === 0) return "Stay For Free";
      else if (userPlanLevel > 0 && level < userPlanLevel) return "Downgrade";
      else if (userPlanLevel > 0 && level >= userPlanLevel) return "Upgrade";
      return "Enjoy this offer now";
    },
    frequencyText() {
      const { frequency } = this;
      return PLANS_FREQUENCY_TEXT[frequency];
    },

    paymentLevel() {
      const { userPlanId, planLevel: userPlanLevel, isAuthenticated } = this;
      const { level: planLevel } = this.plan;

      if (!isAuthenticated || userPlanId === PLANS.FREE) return PAYMENT_LEVEL.FIRST_TIME;
      else if (planLevel === userPlanLevel) return PAYMENT_LEVEL.CURRENT;
      else if (planLevel < userPlanLevel) return PAYMENT_LEVEL.DOWNGRADE;
      return PAYMENT_LEVEL.UPGRADE;
    },

    isCurrent() {
      const { id, userPlanId } = this;
      return id === userPlanId;
    },
    isFree() {
      const { amount, isAuthenticated } = this;
      if (!isAuthenticated) return false;
      return !amount;
    },
    isDisabled() {
      const { id, paymentLevel } = this;

      if (paymentLevel === PAYMENT_LEVEL.DOWNGRADE && id === PLANS.FREE) return true;
      return false;
    },
  },
  data: () => ({
    PLANS,
    PAYMENT_LEVEL,
  }),
  methods: {
    toSlug,
    convertTimestampToDate,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_settings";

.selection-card {
  position: relative;
  box-sizing: border-box;

  .features {
    list-style: none;
    padding: 0;

    .icon {
      margin-top: 0.1em;
    }
  }
}
</style>
