<template>
  <b-row class="flex-column flex-lg-row">
    <b-col class="d-flex flex-column plan_downgrade mb-4">
      <h2>{{ "You won't be able to access" | __t("forms__downgrade__wont-be-able-to-access") }}</h2>

      <div class="flex-grow-1">
        <h3 class="mb-4">{{ currentPlan?.name }}</h3>

        <ul class="features text-left">
          <li v-for="value in currentPlan?.features" :key="String(value)" class="d-flex align-items-start mb-2">
            <b-icon-exclamation-circle class="icon" />
            <!-- <span class="ml-2">{{ value | __t("pricing__pro-" + toSlug(String(value))) }}</span> -->
            <span v-html="value.text" class="ml-2" />
          </li>
        </ul>
      </div>
    </b-col>

    <b-col class="d-none d-lg-flex align-items-center flex-grow-0 p-0">
      <b-icon-arrow-right-short font-scale="3" class="m-n2" />
    </b-col>

    <b-col class="d-flex flex-column plan_new mb-3">
      <h2>{{ "You will still be able to access" | __t("forms__downgrade__still-able-to-access") }}</h2>

      <div class="flex-grow-1">
        <h3 class="mb-4">{{ newPlan?.name }}</h3>

        <ul class="features text-left">
          <li v-for="value in newPlan?.features" :key="String(value)" class="d-flex align-items-start mb-2">
            <!-- <img src="@/assets/icons/checkbox.svg" class="icon" /> -->
            <img :src="require(`@/assets/icons/${value.icon}.svg`)" class="icon" />
            <!-- <span class="ml-2">{{ value | __t("pricing__pro-" + toSlug(String(value))) }}</span> -->
            <span v-html="value.text" class="ml-2" />
          </li>
        </ul>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import { BRow, BCol, BIconArrowRightShort, BIconExclamationCircle } from "bootstrap-vue";

import { toSlug } from "@/helpers/utils";
import { convertTimestampToDate } from "@/helpers/date";

export default {
  name: "DowngradeWarning",
  components: {
    BRow,
    BCol,
    BIconArrowRightShort,
    BIconExclamationCircle,
  },
  props: {
    userPlanId: {
      type: String,
      required: true,
    },
    newPlanId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters("auth", ["fetchPlansOptions"]),
    currentPlan() {
      const plans = this.fetchPlansOptions;
      return plans.find((item) => item.id === this.userPlanId);
    },
    newPlan() {
      const plans = this.fetchPlansOptions;
      return plans.find((item) => item.id === this.newPlanId);
    },
  },
  data: () => ({
    agree: false,
    currentSubscriptionExpirationDate: null,

    errorMessage: "",

    isSending: false,
  }),
  methods: {
    toSlug,
    convertTimestampToDate,
  },
};
</script>
