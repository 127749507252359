<template>
  <div>
    <p class="text-white">
      {{ "Upgrade or downgrade your account." | __t("billing__upgrade-or-downgrade-account") }}
    </p>

    <div class="text-center">
      <b-button
        @click="isShowingUpgrade = true"
        variant="primary"
        class="d-block m-auto mt-2 mb-4 text-uppercase"
        pill
        data-cy="upgrade-plan"
      >
        {{ "Manage Plan" | __t("billing__button__manage-plan") }}
      </b-button>
    </div>

    <b-modal
      v-model="isShowingUpgrade"
      @hidden="isShowingUpgrade = false"
      :title="$options.filters.__t(`Upgrade Account`, `billing__headline-upgrade-account`)"
      centered
      hide-header
      hide-footer
      size="xl"
      content-class="bg-dark"
      data-cy="checkout-modal"
    >
      <user-upgrade @close="isShowingUpgrade = false" :is-showing-breadcrumb="false" />
    </b-modal>
  </div>
</template>

<script>
import { BModal, BButton } from "bootstrap-vue";

import UserUpgrade from "@/components/Base/PricingUpgrade/";

export default {
  name: "BillingUpgrade",
  components: { BModal, BButton, UserUpgrade },
  data: () => ({
    isShowingUpgrade: false,
  }),
};
</script>
