<template>
  <div class="h-100 w-100">
    <em>
      {{ text | __t(`loader__${toSlug(text)}`) }}
    </em>

    <img v-if="mode === 'dark'" src="@/assets/animations/loader-dots.svg" width="25" height="25" class="icon ml-1" />
    <img v-else src="@/assets/animations/loader-dots-black.svg" width="25" height="25" class="icon ml-1" />

    <div v-if="isShowingAfter60Seconds" class="mt-2">
      <strong> {{ textAfter60Seconds | __t(`loader__${toSlug(textAfter60Seconds)}`) }}</strong>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { sleep, toSlug } from "@/helpers/utils";

export default {
  name: "Spinner",
  props: {
    text: {
      type: String,
      default: "Loading",
    },
    textAfter60Seconds: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapState({ mode: (state) => state.customize["dark-mode"] }),
  },
  data: () => ({
    isShowingAfter60Seconds: false,
  }),

  mounted() {
    const { textAfter60Seconds } = this;
    if (textAfter60Seconds) this.startTimer();
  },
  methods: {
    async startTimer() {
      await sleep(60000);

      this.isShowingAfter60Seconds = true;
    },
    toSlug,
  },
};
</script>
