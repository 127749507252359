<template>
  <div
    class="white-card border border"
    :class="{ force_dark: isForceDark, 'theme--dark': isForceDark, 'px-md-4 py-3 px-3': hasPadding, 'text-center': isCentered }"
  >
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "WhiteCard",
  components: {},
  props: {
    rounded: {
      type: String,
      default: "lg",
    },
    isForceDark: {
      type: Boolean,
      default: false,
    },
    isCentered: {
      type: Boolean,
      default: true,
    },
    hasPadding: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState({ mode: (state) => state.customize["dark-mode"] }),
    customClass() {
      const res = [];

      this.rounded ? res.push("rounded-" + this.rounded) : res.push("rounded");
      if (this.isForceDark) res.push("force_dark");
      if (this.isForceDark) res.push("theme--dark");
      if (this.hasPadding) res.push("px-md-4 py-3 px-3");
      if (this.isCentered) res.push("text-center");
      return res;
    },
  },
  data: () => ({
    isShowingUpgrade: false,
  }),
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/_settings";
.white-card {
  position: relative;
  background-color: #fdfbf7;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
  border-radius: 15px;

  .title {
    text-transform: uppercase;
    font-size: 1.3em;
  }

  .subtitle {
    text-transform: uppercase;
    font-size: 1em;
    font-weight: 400;
  }

  .buy-btn {
    font-size: 1.15em;
  }
}

.theme--dark .white-card,
.white-card.force_dark {
  background-color: $black-100;
  border-color: $black-light-100 !important;
}
</style>
