<template>
  <div>
    <b-alert v-if="errorMessage" variant="danger" show data-cy="error-message">
      <p class="text-white m-0">{{ errorMessage }}</p>
    </b-alert>

    <b-row>
      <b-col class="mb-3">
        <div class="p-4">
          <b-form-group>
            <b-form-radio
              v-for="creditCard in itemsSorted"
              :key="creditCard.key"
              v-model="currentPaymentAction"
              :value="creditCard.id"
              class="mb-3"
            >
              <img v-if="creditCard.brand === 'visa'" src="@/assets/icons/visa.svg" height="20" />
              <img v-else-if="creditCard.brand === 'mastercard'" src="@/assets/icons/mastercard.svg" height="20" />

              <span class="text-capitalize ml-4">{{ creditCard.brand }}</span>
              <span class="ml-2">**** {{ creditCard.last4 }}</span>
            </b-form-radio>

            <!-- <b-form-radio v-model="currentPaymentAction" value="credit-card">
              <img src="@/assets/icons/visa.svg" height="20" class="mr-2" />
              <img src="@/assets/icons/mastercard.svg" height="20" class="mr-2" />
              <img src="@/assets/icons/maestro.svg" height="20" />

              <span class="text-capitalize ml-4"><small class="text-graylight">Add new card</small></span>
            </b-form-radio> -->
          </b-form-group>

          <div v-if="currentPaymentAction !== 'credit-card'" class="mt-4 text-right">
            <b-button @click="triggerSubscriptionUpgrade" variant="success" class="w-100" :disabled="isSending" pill>
              {{ "Confirm Order" | __t("button__confirm-order") }}
            </b-button>
          </div>

          <div v-else class="ml-4" style="min-height: 90vh">
            <!-- <checkout-stripe v-on="$listeners" @close="$emit('close')" /> -->
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { STRIPE_UPGRADE_SUBSCRIPTION } from "@/store/actions";

import { BRow, BCol, BFormGroup, BFormRadio, BButton, BAlert } from "bootstrap-vue";

import { PLANS_PRICE_LIST, PLANS_FREQUENCY_LIST } from "@/constants/users";
import { PAYMENT_LEVEL, PAYMENT_METHODS, CURRENCIES_LIST, CURRENCIES_DEFAULT } from "@/constants/billing";

/* import CheckoutStripe from "./Stripe"; */

export default {
  name: "CheckoutStripeSubscription",
  components: { BRow, BCol, BFormGroup, BFormRadio, BButton, BAlert /* CheckoutStripe */ },
  props: {
    newPlanId: {
      type: String,
      required: true,
    },

    amount: {
      type: Number,
      required: true,
    },
    currency: {
      validator: (value) => {
        return CURRENCIES_LIST.includes(value);
      },
      type: String,
      default: CURRENCIES_DEFAULT,
    },
    frequency: {
      validator: (value) => {
        return PLANS_FREQUENCY_LIST.includes(value);
      },
      required: true,
    },
    price: {
      validator: (value) => {
        return PLANS_PRICE_LIST.includes(value);
      },
      required: true,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      userPlanSubscriptionId: (state) => state?.auth?.user?.billing?.subscriptionId,
    }),
    ...mapGetters("userCreditCards", ["itemsSorted"]),
  },
  data: () => ({
    currentPaymentAction: PAYMENT_METHODS.CREDIT_CARD,

    errorCode: null,
    errorMessage: null,

    isSending: false,
  }),
  mounted() {},
  methods: {
    ...mapActions("checkout", { upgradeSubscriptionStripe: STRIPE_UPGRADE_SUBSCRIPTION }),
    async triggerSubscriptionUpgrade() {
      this.isSending = true;
      this.errorMessage = null;

      const {
        userPlanSubscriptionId: subscriptionId,
        newPlanId,
        amount,
        currency,
        frequency,
        price,
        currentPaymentAction: paymentMethodId,
      } = this;
      const { plan: previousPlanId } = this.user.billing;

      try {
        console.log("1");
        const { isSuccess, errorCode, errorMessage, ...others } = await this.upgradeSubscriptionStripe({
          paymentMethodId,
          subscriptionId,
          newPlanId,
          previousPlanId,
          amount,
          currency,
          frequency,
          price,
        });

        console.log("2", isSuccess, errorCode, errorMessage, others);
        if (!isSuccess) {
          this.errorCode = errorCode;
          this.errorMessage = errorMessage || "The payment could not be confirmed. Please contact hello@meetlouis.ai";

          throw new Error(errorCode);
        }

        const paymentLevel = PAYMENT_LEVEL.UPGRADE;
        const payload = { paymentLevel, newPlanId };

        this.$emit("success-subscription", payload);
      } catch (errorData) {
        console.log("err", errorData);
      }

      this.isSending = false;
    },
  },
};
</script>
