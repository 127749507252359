<template>
  <div>
    <div v-if="!isMobile" class="mt-3 mr-3 p-4 bg-dark-jungle rounded">
      <h3 class="text-uppercase">{{ "Billing" | __t("member__headline__billing") }}</h3>
    </div>

    <div v-else @click="handleShowSidebar()" class="mt-3">
      <b-button variant="transparent" class="float-left">
        <img src="@/assets/icons/chevron-left.svg" width="20" height="20" />
      </b-button>

      <h5 class="mt-2 d-inline-block">{{ "Billing" | __t("member__headline__billing") }}</h5>
    </div>

    <billing-desktop v-if="!isMobile" />
    <billing-mobile v-else />

    <b-row v-if="currentPlan === PLANS.FREE" class="mt-4" no-gutters>
      <b-col>
        <div :class="{ 'pr-3': !isMobile }">
          <div :class="{ 'p-4': !isMobile, 'p-2 ml-2 mr-2': isMobile }" class="bg-dark-jungle rounded">
            <h3 v-if="!isMobile" class="mb-0 text-uppercase">{{ "Upgrade Now" | __t("input__upgrade-now") }}</h3>

            <billing-section
              :option-price="PLANS_PRICE.NORMAL"
              :option-frequency="PLANS_FREQUENCY.PER_YEAR"
              :has-countdown="false"
              :has-risk-free-banner="false"
            />
          </div>
        </div>
      </b-col>
    </b-row>

    <spacer />

    <modal-success-plan
      :session-id="sessionId"
      :new-plan-id="newPlanId"
      :previous-plan-id="previousPlanId"
      :payment-level="paymentLevel"
      :is-showing-modal.sync="isShowingSuccessPlan"
    />
    <modal-success-topup
      :session-id="sessionId"
      :previous-credits="previousCredits"
      :is-showing-modal.sync="isShowingSuccessTopup"
    />
    <modal-success-setup :session-id="sessionId" :is-showing-modal.sync="isShowingSuccessSetup" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { FETCH, LISTEN, TRIGGER_EVENT } from "@/store/actions";

import { BRow, BCol } from "bootstrap-vue";

import { PLANS, PLANS_PRICE, PLANS_FREQUENCY } from "@/constants/users";

import BillingSection from "@/components/SpecialOffer/BillingSection";
import ModalSuccessPlan from "@/components/Auth/Billing/Modals/Success/Plan";
import ModalSuccessTopup from "@/components/Auth/Billing/Modals/Success/Topup";
import ModalSuccessSetup from "@/components/Auth/Billing/Modals/Success/Setup";
import Spacer from "@/components/Layouts/Spacer";

import BillingDesktop from "./Desktop";
import BillingMobile from "./Mobile";

export default {
  name: "Billing",
  components: {
    BRow,
    BCol,
    BillingDesktop,
    BillingMobile,
    BillingSection,
    ModalSuccessPlan,
    ModalSuccessTopup,
    ModalSuccessSetup,
    Spacer,
  },
  computed: {
    ...mapState({
      uid: (state) => state.auth.user.uid,
      billing: (state) => state.memberBilling.items,
    }),
    ...mapGetters("auth", ["planLevel"]),
    currentPlan() {
      const { billing } = this;
      return billing.plan || "free";
    },
    isMobile() {
      const { value } = this.$screen.alias;
      return value === "xs" || value === "sm" || value === "md";
    },
  },
  data: () => ({
    sessionId: null,

    newPlanId: null,
    previousPlanId: null,
    paymentLevel: null,

    previousCredits: 0,

    isLoading: true,
    isShowingSuccessPlan: false,
    isShowingSuccessTopup: false,
    isShowingSuccessSetup: false,
    isShowingUpgrade: false,

    PLANS,
    PLANS_PRICE,
    PLANS_FREQUENCY,
  }),
  mounted() {
    this.init();

    this.checkIsSuccess();
    this.checkIsRecentlyJoined();
  },
  methods: {
    ...mapActions("memberBilling", { fetchBilling: FETCH, listenBilling: LISTEN }),
    ...mapActions("trackings", { triggerEvent: TRIGGER_EVENT }),
    async init() {
      this.isLoading = true;

      const { uid } = this;
      await this.fetchBilling({ uid });
      await this.listenBilling({ uid });

      this.isLoading = false;
    },

    checkIsRecentlyJoined() {
      const { plan, isRecentlyJoined, isSuccess } = this;

      if (isSuccess) return;

      if (plan === PLANS.FREE && isRecentlyJoined) {
        this.$router.push({ name: "SpecialOffer" }).catch((e) => console.log(e));
        return true;
      }

      return false;
    },
    checkIsSuccess() {
      const { isSuccess, sessionId, amount, currency, topup = null, mode = null, previousCredits = 0 } = this.$route.query;

      if (isSuccess && sessionId && topup) {
        this.sessionId = sessionId;
        this.topup = topup;
        this.previousCredits = previousCredits;
        this.isShowingSuccessTopup = true;
      } else if (isSuccess && sessionId && mode === "setup") {
        this.sessionId = sessionId;
        this.isShowingSuccessSetup = true;
      } else if (isSuccess && sessionId) {
        const { planId, previousPlanId, paymentLevel } = this.$route.query;

        this.sessionId = sessionId;
        this.newPlanId = planId;
        this.previousPlanId = previousPlanId;
        this.paymentLevel = paymentLevel;

        this.isShowingSuccessPlan = true;

        this.triggerEvent({
          event: "purchase",
          currency,
          value: amount,
          transactionId: sessionId,
          items: [planId],
          amount,
          vueInstance: this,
        });
      } else {
        this.checkIsRecentlyJoined();
      }
    },

    handleShowSidebar() {
      this.$bus.emit("show-sidebar");
    },
  },
};
</script>

<style lang="scss">
.website-url {
  input {
    padding-left: 0px !important;
  }
}
</style>
