<template>
  <div>
    <h5 class="font-handwriting">{{ "2. Payment" | __t("members__step-payment") }}</h5>

    <b-row>
      <b-col class="mb-3">
        <div>
          <b-form-group>
            <b-form-radio v-model="currentPaymentAction" name="some-radios" value="credit-card">
              <img src="@/assets/icons/visa.svg" height="20" class="mr-2" />
              <img src="@/assets/icons/mastercard.svg" height="20" class="mr-2" />
              <img src="@/assets/icons/maestro.svg" height="20" />
            </b-form-radio>
          </b-form-group>

          <div class="ml-4" style="min-height: 90vh">
            <checkout-stripe
              v-on="$listeners"
              :plan-id="planId"
              :amount="amount"
              :frequency="frequency"
              :price="price"
              :payment-level="paymentLevel"
              @close="$emit('close')"
            />
          </div>
        </div>
      </b-col>

      <b-col v-if="0" cols="12" md="6" class="mb-3">
        <checkout-summary :plan-id="planId" :amount="amount" :frequency="frequency" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import { BRow, BCol, BFormGroup, BFormRadio } from "bootstrap-vue";

import { PLANS_FREQUENCY_LIST, PLANS_PRICE_LIST } from "@/constants/users";
import { PAYMENT_LEVEL, PAYMENT_METHODS } from "@/constants/billing";

import CheckoutStripe from "./Stripe";
import CheckoutSummary from "./Summary";

export default {
  name: "UserUpgrade",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    CheckoutStripe,
    CheckoutSummary,
  },
  props: {
    planId: {
      type: String,
      required: true,
    },
    userPlanId: {
      type: String,
      default: null,
    },

    plan: {
      type: Object,
      required: true,
    },

    paymentLevel: {
      type: String,
      default: null,
    },
    frequency: {
      validator: (value) => {
        return PLANS_FREQUENCY_LIST.includes(value);
      },
      required: true,
    },
    price: {
      validator: (value) => {
        return PLANS_PRICE_LIST.includes(value);
      },
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      userPlanAmount: (state) => state?.auth?.user?.billing?.amount,
      userPlanFrequency: (state) => state?.auth?.user?.billing?.frequency,
    }),
    ...mapGetters("auth", ["isRecentlyJoined"]),
    amount() {
      const { userPlanAmount, plan, frequency, price, paymentLevel } = this;

      if (paymentLevel === PAYMENT_LEVEL.UPGRADE) return plan[frequency][price] - userPlanAmount;
      if (paymentLevel === PAYMENT_LEVEL.DOWNGRADE) return null;
      return plan[frequency][price];
    },
  },
  data: () => ({
    currentPaymentAction: PAYMENT_METHODS.CREDIT_CARD,

    isSuccess: false,
  }),
};
</script>
