<template>
  <div class="base-checkbox w-100">
    <b-form-checkbox
      :value="true"
      :unchecked-value="false"
      v-model="internalValue"
      :name="name"
      :disabled="disabled"
      :id="name"
      @input="$emit('input', $event)"
    >
      {{ placeholder }}
    </b-form-checkbox>
  </div>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";

export default {
  name: "BaseCheckbox",
  components: { BFormCheckbox },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_settings";
.base-input input {
  border-radius: 25px !important;
}
.theme--default .base-input {
  input {
    background-color: white;
    color: $black-lighter;
    border: 1px $gray-light-100 solid;
  }
  input[disabled="disabled"] {
    background-color: $white-100;
    color: $gray-darker;
  }
}
.theme--dark .base-input {
  input {
    background-color: $blue-deeper;
    color: $white;
    border: 1px $gray-darker solid;
  }
  input[disabled="disabled"] {
    background-color: $gray-darker-100;
    color: $gray-darker;
  }
}
</style>
