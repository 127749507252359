<template>
  <div>
    <b-breadcrumb v-if="isShowingBreadcrumb">
      <b-breadcrumb-item
        v-for="(item, index) in breadcrumb"
        @click.prevent="selectStep"
        :key="item.text"
        :id="`breadcrumb-item__${item.key}`"
        :disabled="index > currentStepIndex || index === breadcrumb.length - 1"
        :text="item.text"
        :active="item.active"
      />
    </b-breadcrumb>

    <upgrade-selection
      v-if="currentStep === STEPS.SELECTION"
      @select-plan="selectPlan"
      :user-plan-id="originalUserPlanId"
      :price="price"
      :frequency.sync="frequency"
      :is-switcher-disabled="isSwitcherDisabled"
    />

    <div v-else-if="currentStep === STEPS.PAYMENT">
      <checkout-downgrade
        v-if="paymentLevel === PAYMENT_LEVEL.DOWNGRADE"
        :user-plan-id="originalUserPlanId"
        :selected-plan-id="selectedPlanId"
        :price="price"
        :frequency="frequency"
        @success="triggerContinue"
      />

      <checkout-upgrade-subscription
        v-else-if="paymentLevel === PAYMENT_LEVEL.UPGRADE && frequency !== PLANS_FREQUENCY.SINGLE_TIME"
        :user-plan-id="originalUserPlanId"
        :plan-id="selectedPlanId"
        :plan="selectedPlan"
        :payment-level="paymentLevel"
        :price="price"
        :frequency="frequency"
        @success-subscription="triggerContinue"
      />

      <checkout-upgrade
        v-else
        :user-plan-id="originalUserPlanId"
        :plan-id="selectedPlanId"
        :plan="selectedPlan"
        :payment-level="paymentLevel"
        :price="price"
        :frequency="frequency"
        @success="triggerContinue"
        @close="currentStep = STEPS.SELECTION"
      />
    </div>

    <upgrade-confirmation
      v-else
      v-on="$listeners"
      :previous-plan-id="successData?.previousPlanId"
      :new-plan-id="successData?.newPlanId"
      :payment-level="successData?.paymentLevel"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { SUBSCRIPTION, STRIPE_CANCEL_SUBSCRIPTION } from "@/store/actions";

import { BBreadcrumb, BBreadcrumbItem } from "bootstrap-vue";

import {
  PLANS,
  PLANS_FREQUENCY,
  PLANS_FREQUENCY_LIST,
  PLANS_PRICE_LIST,
  DEFAULT_FREQUENCY_PLAN,
  DEFAULT_PRICE_PLAN,
} from "@/constants/users";
import { PAYMENT_LEVEL, STEPS, STEPS_STEPPER } from "@/constants/billing";

import { sleep, toSlug } from "@/helpers/utils";
import { convertTimestampToDate } from "@/helpers/date";

import UpgradeSelection from "./Selection/";
import CheckoutDowngrade from "./Downgrade/";
import CheckoutUpgrade from "./Upgrade/";
import CheckoutUpgradeSubscription from "./UpgradeSubscription/";
import UpgradeConfirmation from "./Confirmation";

export default {
  name: "PricingUpgrade",
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    UpgradeSelection,
    CheckoutDowngrade,
    CheckoutUpgrade,
    CheckoutUpgradeSubscription,
    UpgradeConfirmation,
  },
  props: {
    optionPlanId: {
      type: String,
      default: null,
    },
    optionFrequency: {
      validator: (value) => {
        return PLANS_FREQUENCY_LIST.includes(value);
      },
      type: String,
      default: null,
    },
    optionPrice: {
      validator: (value) => {
        return PLANS_PRICE_LIST.includes(value);
      },
      type: String,
      default: null,
    },

    successPlanId: {
      type: String,
      default: null,
    },
    successPaymentLevel: {
      type: String,
      default: null,
    },

    /* overrideCurrentStep: {
      type: String,
      default: null,
    }, */

    isSuccess: {
      type: Boolean,
      default: false,
    },
    isPreviousEnabled: {
      type: Boolean,
      default: false,
    },
    isShowingBreadcrumb: {
      type: Boolean,
      default: true,
    },
    isSwitcherDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      userPlanId: (state) => state?.auth?.user?.billing?.plan,
      userPlanRenewAt: (state) => state?.auth?.user?.billing?.renewAt,
      userPlanAmount: (state) => state?.auth?.user?.billing?.amount,
      userPlanPrice: (state) => state?.auth?.user?.billing?.price,
      userPlanFrequency: (state) => state?.auth?.user?.billing?.frequency,
    }),
    ...mapGetters("auth", ["fetchPlansOptions", "planLevel", "isAuthenticated", "isRecentlyJoined"]),
    breadcrumb() {
      const { currentStep } = this;

      return STEPS_STEPPER.map((step) => ({
        key: step.key,
        text: this.$options.filters.__t(step.label, step.translationKey),
        active: currentStep === step.key,
      }));
    },

    selectedPlan() {
      const { selectedPlanId } = this;
      const plans = this.fetchPlansOptions;
      return plans.find(({ id }) => id === selectedPlanId) || {};
    },
    currentStepIndex() {
      const { currentStep } = this;
      return STEPS_STEPPER.findIndex(({ key }) => key === currentStep);
    },

    paymentLevel() {
      const { originalUserPlanId, originalPlanLevel, isAuthenticated } = this;
      const { level: planLevel } = this.selectedPlan;

      if (!isAuthenticated || originalUserPlanId === PLANS.FREE) return PAYMENT_LEVEL.FIRST_TIME;
      else if (planLevel === originalPlanLevel) return PAYMENT_LEVEL.CURRENT;
      else if (planLevel < originalPlanLevel) return PAYMENT_LEVEL.DOWNGRADE;
      return PAYMENT_LEVEL.UPGRADE;
    },
  },
  data: () => ({
    successData: null,

    price: DEFAULT_PRICE_PLAN,
    frequency: DEFAULT_FREQUENCY_PLAN,

    originalUserPlanId: null,
    originalPlanLevel: null,

    isDowngrade: false,

    selectedPlanId: null,
    currentPaymentAction: "credit-card",
    currentConfirmationAction: null,
    currentEndingDate: null,

    newPlanName: null,

    subscriptionStatus: null,

    currentStep: STEPS.SELECTION,

    PLANS_FREQUENCY,
    PAYMENT_LEVEL,
    STEPS,
  }),
  created() {
    const {
      userPlanFrequency,
      userPlanPrice,
      optionPlanId,
      optionFrequency,
      optionPrice,
      userPlanId,
      planLevel: userPlanLevel,
      successPlanId,
      successPaymentLevel,
      // overrideCurrentStep,
      isSuccess,
    } = this;

    this.frequency = userPlanFrequency || optionFrequency || DEFAULT_FREQUENCY_PLAN;
    this.price = userPlanPrice || optionPrice || DEFAULT_PRICE_PLAN;
    // this.currentStep = overrideCurrentStep || STEPS.SELECTION;

    if (optionPlanId) {
      this.selectedPlanId = optionPlanId;
      this.currentStep = STEPS.PAYMENT;
    }

    if (userPlanId) {
      this.originalUserPlanId = userPlanId;
    }

    if (userPlanLevel) {
      this.originalPlanLevel = userPlanLevel;
    }

    if (isSuccess) {
      this.successData = { newPlanId: successPlanId, paymentLevel: successPaymentLevel };
      this.currentStep = STEPS.CONFIRMATION;
    }
  },
  methods: {
    ...mapActions("checkout", { cancelStripeSubscription: STRIPE_CANCEL_SUBSCRIPTION }),
    ...mapActions("billing", { getSubscription: SUBSCRIPTION }),
    async triggerContinue(successData) {
      this.successData = successData;
      this.currentStep = STEPS.CONFIRMATION;

      await sleep(10000);

      this.$emit("success-redirect");
    },

    selectPlan(value) {
      this.selectedPlanId = value;
      this.currentStep = STEPS.PAYMENT;
    },
    selectStep(value) {
      const { target } = value;
      const $elParent = target.tagName === "LI" ? target : target.closest("li");

      const { currentStepIndex, breadcrumb } = this;

      if (currentStepIndex === breadcrumb.length - 1) return;

      // eslint-disable-next-line no-unused-vars
      const [_, clickedStepKey] = $elParent.id.split("__");
      const clickedStepIndex = STEPS_STEPPER.findIndex(({ key }) => key === clickedStepKey);

      if (clickedStepIndex < currentStepIndex) {
        this.currentStep = clickedStepKey;
      }
    },

    toSlug,
    convertTimestampToDate,
  },
};
</script>

<style lang="scss" scoped>
.features {
  list-style: none;
  margin: 10px 0;
  padding: 0;
}
</style>
