<template>
  <div>
    <h5 class="title font-handwriting mb-5">{{ "1. Select Your Plan" | __t("members__step-select-your-plan") }}</h5>

    <div v-if="!isSwitcherDisabled">
      <div
        v-if="frequency !== PLANS_FREQUENCY.SINGLE_TIME && userPlanId === PLANS.FREE && !isSwitcherDisabled"
        class="pb-4 text-center"
      >
        <period-switcher v-model="isYearly" :frequency="frequency" :price="price" />
      </div>
    </div>

    <selection-prices
      :user-plan-id="userPlanId"
      :frequency="frequency"
      :price="price"
      @select-plan="selectPlan"
      :is-showing-free="isShowingFree"
    />
  </div>
</template>

<script>
import {
  PLANS,
  PLANS_FREQUENCY,
  PLANS_FREQUENCY_LIST,
  PLANS_PRICE_LIST,
  DEFAULT_PRICE_PLAN,
  DEFAULT_FREQUENCY_PLAN,
} from "@/constants/users";

import PeriodSwitcher from "./PeriodSwitcher";
import SelectionPrices from "./SelectionPrices";

export default {
  name: "UpgradeSelection",
  components: { SelectionPrices, PeriodSwitcher },
  props: {
    userPlanId: {
      type: String,
      default: PLANS.FREE,
    },

    frequency: {
      validator: (value) => {
        return PLANS_FREQUENCY_LIST.includes(value);
      },
      type: String,
      default: DEFAULT_FREQUENCY_PLAN,
    },
    price: {
      validator: (value) => {
        return PLANS_PRICE_LIST.includes(value);
      },
      type: String,
      default: DEFAULT_PRICE_PLAN,
    },

    optionIsShowingFree: {
      type: Boolean,
      default: false,
    },
    isSwitcherDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isYearly: {
      get() {
        if (this.frequency === PLANS_FREQUENCY.PER_YEAR) return 1;
        return 0;
      },
      set(value) {
        if (value) {
          this.$emit("update:frequency", PLANS_FREQUENCY.PER_YEAR);
        } else {
          this.$emit("update:frequency", PLANS_FREQUENCY.PER_MONTH);
        }
      },
    },
    isShowingFree() {
      const { userPlanId, optionIsShowingFree } = this;

      if (optionIsShowingFree) return true;
      return userPlanId !== PLANS.FREE;
    },
  },
  data: () => ({
    PLANS,
    PLANS_FREQUENCY,
  }),
  methods: {
    selectPlan(planId) {
      this.$emit("select-plan", planId);
    },
  },
};
</script>
