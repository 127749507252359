<template>
  <b-modal
    v-model="localIsShowingModal"
    @hidden="localIsShowingModal = false"
    size="lg"
    centered
    hide-footer
    content-class="bg-dark"
    body-class="d-flex flex-column"
    :no-close-on-backdrop="isWaitingForConfirmation"
    :no-close-on-esc="isWaitingForConfirmation"
  >
    <template #modal-header>
      <div class="w-100">
        <h5 class="font-handwriting">
          {{ "3. Success" | __t("billing__headline__3-success") }}
        </h5>
      </div>
    </template>

    <div>
      <spinner-small v-if="!isSuccess" :text="`Waiting for confirmation` | __t('status____waiting-for-confirmation')" />

      <div v-else>
        <p>
          {{ "Thank you for your payment." | __t("`billing__thankyou-for-your-payment") }}
        </p>

        <p>
          <strong>{{ (creditsAvailable - previousCredits).toLocaleString("en-US") }}</strong>
          {{ "credits have been added." | __t(`billing__credits-have-been-added`) }}
        </p>

        <div class="text-right text-white mt-4">
          <b-button
            variant="success"
            @click="
              $emit('success-redirect');
              localIsShowingModal = false;
            "
            pill
          >
            <span class="text-uppercase">{{ "Continue" | __t("button__continue") }}</span>
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import { BModal, BButton } from "bootstrap-vue";

import { PAYMENT_LEVEL } from "@/constants/billing";

import { sleep } from "@/helpers/utils";

import SpinnerSmall from "@/components/Spinner/Small";

export default {
  name: "SuccessTopup",
  components: { BModal, BButton, SpinnerSmall },
  props: {
    sessionId: {
      type: String,
      default: "",
    },

    previousCredits: {
      type: [String, Number],
      default: 0,
    },

    isShowingModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({ creditsAvailable: (state) => state?.auth?.user?.billing?.topup?.credits || 0 }),
    localIsShowingModal: {
      get() {
        return this.isShowingModal;
      },
      set(value) {
        this.$emit("update:is-showing-modal", value);
      },
    },
    isSuccess() {
      const { previousCredits, creditsAvailable } = this;
      return +previousCredits !== +creditsAvailable;
    },
  },
  data: () => ({
    isWaitingForConfirmation: true,

    PAYMENT_LEVEL,
  }),
  watch: {
    isSuccess(newValue) {
      if (newValue) this.startConfetti();
    },
  },
  methods: {
    async startConfetti() {
      this.$confetti.start();

      await sleep(3000);
      this.$confetti.stop();
    },
  },
};
</script>
