<template>
  <div class="downgrade-screen">
    <h5 class="font-handwriting">{{ "2. Downgrade plan" | __t("members__step-downgrade-plan") }}</h5>

    <b-alert v-if="errorMessage" variant="danger" data-cy="error-message" show>
      <p class="text-white m-0">{{ errorMessage || "An error happened. Please try again later or contact us" }}</p>
    </b-alert>

    <div>
      <downgrade-warning :user-plan-id="userPlanId" :new-plan-id="selectedPlanId" />

      <b-form @submit="triggerDowngrade">
        <div class="mt-4">
          <b-row>
            <b-col class="text-left" cols="12">
              <h3 class="mb-4">{{ "Estimate for your future plan" | __t("forms__downgrade__estimate-for-your-future-plan") }}</h3>

              <p>
                <base-checkbox :placeholder="checkboxPlaceholder" :disabled="isSending" v-model="agree"></base-checkbox>
              </p>

              <p>
                <b>{{ "Total:" | __t("forms__downgrade__total") }}</b> <span>${{ newAmount }}</span>
              </p>

              <div class="text-right">
                <b-button variant="warning" :disabled="!agree || isSending" @click="triggerDowngrade" pill>
                  {{ "Downgrade plan" | __t("forms__downgrade__downgrade-plan") }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { STRIPE_CANCEL_SUBSCRIPTION } from "@/store/actions";

import { BRow, BCol, BForm, BButton, BAlert } from "bootstrap-vue";

import { PLANS_FREQUENCY_TEXT, PLANS_FREQUENCY_LIST, PLANS_PRICE_LIST } from "@/constants/users";
import { PAYMENT_LEVEL } from "@/constants/billing";

import { toSlug } from "@/helpers/utils";
import { convertTimestampToDate } from "@/helpers/date";

import BaseCheckbox from "@/components/Forms/Base/BaseCheckbox";

import DowngradeWarning from "./Warning";

export default {
  name: "CheckoutDowngrade",
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BAlert,
    BaseCheckbox,
    DowngradeWarning,
  },
  props: {
    selectedPlanId: {
      type: String,
      default: "",
    },

    frequency: {
      validator: (value) => {
        return PLANS_FREQUENCY_LIST.includes(value);
      },
      type: String,
      required: true,
    },
    price: {
      validator: (value) => {
        return PLANS_PRICE_LIST.includes(value);
      },
      type: String,
      required: true,
    },
  },
  data: () => ({
    agree: false,

    errorMessage: "",

    isSending: false,
  }),

  computed: {
    ...mapState({
      userPlanId: (state) => state?.auth?.user?.billing?.plan,
      userSubscriptionId: (state) => state?.auth?.user?.billing?.subscriptionId,
      userPlanRenewAt: (state) => state?.auth?.user?.billing?.renewAt,
      userPlanAmount: (state) => state?.auth?.user?.billing?.amount,
      userPlanPrice: (state) => state?.auth?.user?.billing?.price,
      userPlanFrequency: (state) => state?.auth?.user?.billing?.frequency,
    }),
    ...mapGetters("auth", ["fetchPlansOptions", "isRecentlyJoined"]),

    checkboxPlaceholder() {
      const { userPlanRenewAt, newAmount, userPlanFrequency } = this;

      const template = this.$options.filters.__t(
        "I understand that my account will be downgraded to {plan_name} on {date} and will be billed US$ {newAmount} {userPlanFrequency}.",
        "forms__downgrade__i-understand-checkbox"
      );

      return template
        .replace("{plan_name}", this.newPlan?.name)
        .replace("{date}", convertTimestampToDate(userPlanRenewAt))
        .replace("{newAmount}", newAmount)
        .replace("{userPlanFrequency}", `${PLANS_FREQUENCY_TEXT[userPlanFrequency].toLowerCase()}`);
    },

    /* You agree that your Netflix membership will continue and that we will charge the updated monthly fee until you cancel. You may cancel at any time to avoid future charges. To cancel, go to Netflix.com/CancelPlan. */
    currentPlan() {
      const plans = this.fetchPlansOptions;
      return plans.find((item) => item.id === this.userPlanId);
    },
    newPlan() {
      const plans = this.fetchPlansOptions;
      return plans.find((item) => item.id === this.selectedPlanId);
    },
    newAmount() {
      const { userPlanAmount, newPlan, frequency, price, paymentLevel } = this;

      if (paymentLevel === PAYMENT_LEVEL.UPGRADE) return newPlan[frequency][price] - userPlanAmount;
      if (paymentLevel === PAYMENT_LEVEL.DOWNGRADE) return null;
      return newPlan[frequency][price];
    },
  },
  methods: {
    ...mapActions("checkout", { cancelStripeSubscription: STRIPE_CANCEL_SUBSCRIPTION }),
    async triggerDowngrade() {
      if (!this.agree) return;

      this.isSending = true;
      this.errorMessage = "";

      try {
        const {
          selectedPlanId,
          userPlanId: planId,
          userPlanFrequency: frequency,
          userPlanPrice: price,
          userSubscriptionId: subscriptionId,
        } = this;

        const { isSuccess, errorMessage } = await this.cancelStripeSubscription({
          subscriptionId,
          currentPlanId: planId,
          newPlanId: selectedPlanId,
          frequency,
          price,
        });

        if (isSuccess) {
          const paymentLevel = PAYMENT_LEVEL.DOWNGRADE;
          this.$emit("success", { paymentLevel, previousPlanId: planId, newPlanId: selectedPlanId });
        } else {
          this.errorMessage = errorMessage;
        }
      } catch (error) {
        console.log("ERR", error);
        this.errorMessage = error;
      }

      this.isSending = false;
    },

    toSlug,
    convertTimestampToDate,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_settings";

.downgrade-screen {
  h2 {
    font-size: 1.3em;
    margin-bottom: 1em;
  }

  .icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-top: 0.1em;
  }
  .plan_downgrade {
    .icon {
      filter: invert(51%) sepia(20%) saturate(5527%) hue-rotate(335deg) brightness(101%) contrast(97%);
    }
  }
  .plan_new {
    .icon {
      filter: invert(65%) sepia(100%) saturate(359%) hue-rotate(96deg) brightness(89%) contrast(82%);
    }
  }
  .features {
    list-style: none;
    padding: 0;
  }
}
.theme--default .downgrade-screen {
  .plan_downgrade {
    .white-card {
      background-color: lighten($warning, 33);
    }
  }
  .plan_new {
    .white-card {
      background-color: lighten($success, 49);
    }
  }
}
.theme--dark .downgrade-screen {
  .plan_downgrade {
    .white-card {
      background-color: darken($warning, 53) !important;
    }
  }
  .plan_new {
    .white-card {
      background-color: darken($success, 43) !important;
    }
  }
}
</style>
