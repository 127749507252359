<template>
  <div class="period-switcher border rounded-full text-center p-1 d-flex d-sm-inline-flex">
    <b-button
      :class="{ is_active: value === 0, 'mr-2': !isMobile }"
      class="flex-grow-1"
      :variant="btnVariant(value === 0)"
      @click="$emit('input', 0)"
      pill
    >
      <span :class="{ 'text-white': !value }" class="text-graylight">
        {{ "Monthly" | __t("pricing__monthly") }}
      </span>
    </b-button>

    <b-button
      class="flex-grow-1"
      :variant="btnVariant(value === 1)"
      :class="{ is_active: value === 1 }"
      @click="$emit('input', 1)"
      pill
    >
      <span :class="{ 'text-white': value }" class="text-graylight">
        {{ "Yearly" | __t("pricing__yearly") }}
      </span>
      <br />
      <b v-if="price === PLANS_PRICE.ULTRA_SPECIAL" class="text-success">
        {{ "(6 Months Free)" | __t("pricing__save-six-month") }}
      </b>
      <b v-else class="text-success">{{ "(2 Months Free)" | __t("pricing__save-two-month") }}</b>
    </b-button>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";

import { PLANS_PRICE } from "@/constants/users";

export default {
  name: "UserBilling",
  components: { BButton },
  props: {
    value: {
      type: Number,
      default: 0,
    },

    price: {
      type: String,
      required: true,
    },
    frequency: {
      type: String,
      required: true,
    },
  },
  computed: {
    isMobile() {
      const { value } = this.$screen.alias;
      return value === "xs" || value === "sm" || value === "md";
    },
  },
  data: () => ({
    PLANS_PRICE,
  }),
  methods: {
    btnVariant(isActive) {
      return isActive ? "dark" : "default";
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_settings";
.period-switcher {
  border-radius: 50px;
  // border-radius: 10px;
}

.theme--dark .period-switcher {
  background-color: $black-100;
  border-color: $black-light-100 !important;

  .is_active {
    cursor: default;
    pointer-events: none;
  }
  .btn-dark {
    background-color: white;
    color: black;
  }
}
</style>
