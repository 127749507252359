<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="6"
        lg="4"
        :xl="plans.length === 3 ? 4 : 3"
        v-for="plan in plans"
        :key="plan.id"
        class="mb-5 mb-xl-0 mt-3"
      >
        <price-card
          :id="plan.id"
          :plan="plan"
          :frequency="frequency"
          :price="price"
          :user-plan-id="userPlanId"
          :is-popular="plan.id === popularPlanId"
          @click="$emit('select-plan', plan.id)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { BRow, BCol } from "bootstrap-vue";

import { PLANS, PLANS_FREQUENCY_LIST, PLANS_PRICE_LIST, DEFAULT_POPULAR_PLAN } from "@/constants/users";

import PriceCard from "./SelectionPricesCard";

export default {
  name: "Cards",
  components: { BRow, BCol, PriceCard },
  props: {
    userPlanId: {
      type: String,
      default: PLANS.FREE,
    },
    popularPlanId: {
      type: String,
      default: DEFAULT_POPULAR_PLAN,
    },

    frequency: {
      validator: (value) => {
        return PLANS_FREQUENCY_LIST.includes(value);
      },
      required: true,
    },
    price: {
      validator: (value) => {
        return PLANS_PRICE_LIST.includes(value);
      },
      required: true,
    },

    isShowingFree: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters("auth", ["fetchPlansOptions"]),
    plans() {
      const { fetchPlansOptions: plans, isShowingFree } = this;

      if (isShowingFree) return plans;
      return plans.filter((plan) => plan.id !== "free");
    },
    isBeta() {
      return this.billing.isBeta;
    },
  },
  data: () => ({
    PLANS_PRICE_LIST,
  }),
  methods: {},
};
</script>
