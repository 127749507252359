<template>
  <p @mouseenter="isHovering = true" @mouseleave="isHovering = false">
    <b-button
      v-if="isHovering"
      @click="triggerRemovePaymentMethod"
      variant="transparent"
      class="float-right"
      :disabled="isSending"
    >
      <img src="@/assets/icons/square-trash.svg" class="icon" />
    </b-button>

    <img v-if="brand === 'visa'" src="@/assets/icons/visa.svg" height="20" />
    <img v-else-if="brand === 'mastercard'" src="@/assets/icons/mastercard.svg" height="20" />
    <span class="ml-2">**** {{ last4 }}</span>
    <br />
    <small v-if="expMonth" class="text-graylight">{{ expMonth }} / {{ expYear }}</small>
  </p>
</template>

<script>
import { mapActions } from "vuex";
import { REMOVE } from "@/store/actions";

import { BButton } from "bootstrap-vue";

export default {
  name: "Card",
  components: { BButton },
  props: {
    id: {
      type: String,
      required: true,
    },
    brand: {
      type: String,
      default: "",
    },
    last4: {
      type: [String, Number],
      default: "",
    },
    expMonth: {
      type: [String, Number],
      default: "",
    },
    expYear: {
      type: [String, Number],
      default: "",
    },
  },
  data: () => ({
    isSending: false,
    isHovering: false,
  }),
  methods: {
    ...mapActions("userCreditCards", { removePaymentMethod: REMOVE }),
    async triggerRemovePaymentMethod() {
      this.isSending = true;

      const { id: paymentMethodId } = this;
      await this.removePaymentMethod({ paymentMethodId });

      this.isSending = false;
    },
  },
};
</script>
