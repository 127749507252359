<template>
  <div>
    <div id="checkout" />

    <spinner-small v-if="isLoading" text="Checking connection" />

    <div class="text-right mt-4">
      <b-button variant="empty" @click="handleCancel" data-cy="cancel-button">
        <span class="text-info">{{ "Cancel" | __t("button__cancel") }}</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { STRIPE_INIT_CHECKOUT, TRIGGER_EVENT } from "@/store/actions";

import { BButton } from "bootstrap-vue";

import { PLANS_PRICE_LIST, PLANS_FREQUENCY_LIST } from "@/constants/users";
import { CURRENCIES_LIST, CURRENCIES_DEFAULT } from "@/constants/billing";

import SpinnerSmall from "@/components/Spinner/Small";

export default {
  name: "CheckoutStripe",
  components: { BButton, SpinnerSmall },
  props: {
    planId: {
      type: String,
      required: true,
    },

    amount: {
      type: Number,
      required: true,
    },
    currency: {
      validator: (value) => {
        return CURRENCIES_LIST.includes(value);
      },
      type: String,
      default: CURRENCIES_DEFAULT,
    },
    frequency: {
      validator: (value) => {
        return PLANS_FREQUENCY_LIST.includes(value);
      },
      required: true,
    },
    price: {
      validator: (value) => {
        return PLANS_PRICE_LIST.includes(value);
      },
      required: true,
    },
    paymentLevel: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapState({ stripe: (state) => state.checkout.stripe }),
  },
  data: () => ({
    checkout: null,

    isLoading: true,
  }),
  async mounted() {
    const { email: emailTemporary } = this.$route.query;
    const { planId, frequency, amount, currency, price, paymentLevel } = this;

    this.triggerEvent({ event: "checkout", value: amount, amount, currency, vueInstance: this });

    const { clientSecret } = await this.initCheckoutStripe({ planId, frequency, price, amount, paymentLevel, emailTemporary });

    this.checkout = await this.stripe.initEmbeddedCheckout({ clientSecret });
    this.checkout.mount("#checkout");

    this.isLoading = false;
  },
  beforeDestroy() {
    this.checkout.destroy();
  },
  methods: {
    ...mapActions("checkout", { initCheckoutStripe: STRIPE_INIT_CHECKOUT }),
    ...mapActions("trackings", { triggerEvent: TRIGGER_EVENT }),
    handleCancel() {
      this.checkout.destroy();
      this.$emit("close");
    },
  },
};
</script>

<style>
#checkout iframe {
  position: relative !important;
}
</style>
